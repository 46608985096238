<template lang="">
  <Card>
    <template #header> İlan Bilgileri </template>
    <div class="row">
      <div class="col-md-4 col-12">
        <!-- <div class="form-group">
          <label for="">Fiili Kullanım Niteligi </label>
          <select name="" id="" class="form-control">
            <option value="">a</option>
          </select>
        </div> -->
        <div class="from-group">
          <label style="font-size: 12px; font-weight: 400;" for="">Fiili Kullanım Niteliği </label>
          <MultiSelect :options="options1" :multiple="false" />
        </div>
      </div>
      <div class="col-md-4 col-12">
        <!-- <div class="form-group">
          <label for="">Fiili Kullanım Amacı </label>
          <select name="" id="" class="form-control">
            <option value="">a</option>
          </select>
        </div> -->
        <div class="from-group">
          <label style="font-size: 12px; font-weight: 400;" for="">Fiili Kullanım Amacı </label>
          <MultiSelect :options="options2" :multiple="false" />
        </div>
      </div>
      <div class="col-md-4 col-12">
        <!-- <div class="form-group">
          <label for="">İlan Grubu </label>
          <select name="" id="" class="form-control">
            <option value="">a</option>
          </select>
        </div> -->
        <div class="from-group">
          <label style="font-size: 12px; font-weight: 400;" for="">İlan Grubu</label>
          <MultiSelect :options="options3" :multiple="false" />
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="name">İlan Başlığı</label>
          <textarea name="" id="" rows="3" class="form-control"></textarea>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="name">3D Görüntü Web Adresi</label>
          <input type="text" class="form-control" />
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="name">Video Web Adresi </label>
          <input type="text" class="form-control" />
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="name">Açıklama</label>
          <textarea name="" id="" rows="3" class="form-control"></textarea>
        </div>
      </div>
    </div>

    <div class="form-group d-flex">
      <button class="btn btn-success ml-auto">Kaydet</button>
    </div>
  </Card>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
  mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "İlan / Detay", route: "/adverts/find/detail/1" },
      ]);
    },
  data() {
    return {
      options: [
        "Şekerbank",
        "Odeobank",
        "Vakıfbank",
        "Alternatif Bank",
        "Alternatif Leasing",
      ],
      options1: [
        { text: "Şekerbank", value: "1" },
        { text: "Odeobank", value: "2" },
      ],

      options2: [
        { text: "Şekerbank", value: "1" },
        { text: "Odeobank", value: "2" },
      ],
      options3: [
        { text: "a", value: "1" },
        { text: "b", value: "2" },
      ],
    };
  },
  components: {
    VarlikList: () => import("@/components/property/DetailTabs/VarlikList"),
  },
};
</script>
<style lang="scss" scoped>
.card-sub-header {
  background-color: $dveb-divider;
  padding: 1rem;
  font-size: 1.2rem;
  font-weight: 500;
  margin: 1rem 0;
}
</style>
